<script setup lang="ts">
import { SpNavigationTestIds } from '@dev.smartpricing/sp-vue-components'
import { FunctionalIds } from '../constants/funcitonalIds'
import { getMainNavigationItems, getBottomNavigationItems } from '@/config/navigationItems'
import { useSideDrawerStore } from '~/store/sideDrawer'
import { useDateDetailsStore } from '~/store/dateDetails'
import { AnimationTimes } from '~/config/AnimationTimes'
import { TestIds } from '../constants/TestIds'

const backgroundColor = computed(() => {
	const routeName = useRoute().name

	switch (routeName) {
		case 'calendar':
			return 'bg-white'
		case 'settings-accommodation':
		case 'settings-language':
		case 'settings-notifications':
		case 'settings-billing':
		case 'updates':
		case 'dashboard':
		case 'strategies':
			return 'bg-secondary-gray-100'
		default:
			return 'bg-main-blue-50'
	}
})

useRouter().beforeEach(() => {
	const sideDrawerStore = useSideDrawerStore()
	useDateDetailsStore().reset()
	sideDrawerStore.show = false
	setTimeout(() => {
		sideDrawerStore.closeDrawer()
	}, AnimationTimes.M)
})

const navigationTestIds: SpNavigationTestIds = {
	container: TestIds.NavbarContainer,
}
</script>

<template>
	<div class="relative">
		<div class="grid grid-cols-[60px_1fr]">
			<div class="relative z-[99999]">
				<SpNavigationDesktop
					:main-navigation-items="getMainNavigationItems()"
					:bottom-navigation-items="getBottomNavigationItems()"
					class="fixed"
					:test-ids="navigationTestIds"
				/>
			</div>
			<div class="relative flex min-h-screen w-full flex-col" :class="backgroundColor">
				<div class="sticky top-0 z-20 bg-white shadow">
					<CommonBannerManager class="sticky z-30 pb-3" />
					<div :id="FunctionalIds.ControlBarContainer"></div>
				</div>
				<slot />
				<ModalManager />
				<CommonBottomControlBarContainer />
				<DevToolsSalesModesBadge class="z-50" />
				<div class="fixed left-0 right-0 top-0 z-[9999] h-0 lg:left-[60px] lg:z-[999]" id="dialogs-container"></div>
				<div class="fixed bottom-0 left-0 right-0 top-0 z-[9999] h-0" id="dropdowns-container"></div>
				<CommonDrawer />
			</div>
		</div>

		<div :id="FunctionalIds.TooltipContainer" class="absolute left-0 top-0 z-20" style="z-index: 99" />
		<CommonNotificationsManager />
		<PopupManager class="z-50" />
		<MakeWishManager />
	</div>
</template>
