<script lang="ts" setup>
import { Teleport } from 'vue'
import { TranslationKey } from '~/i18n/TranslationKeys'
import { SpSvg } from '~~/src/autogen/SpSvg'
import { AnimationTimes } from '~~/src/config/AnimationTimes'
import { ButtonSizes } from '~~/src/constants/buttonSizes'
import { ButtonTypes } from '~~/src/constants/buttonTypes'
import { Directions } from '~~/src/constants/directions'
import { IconSizes } from '~~/src/constants/iconSizes'
import { TextSizes } from '~~/src/constants/textSizes'
import { UserflowId } from '~~/src/constants/UserflowId'
import { useDateDetailsStore } from '~~/src/store/dateDetails'
import { useSideDrawerStore } from '~~/src/store/sideDrawer'

const {
	show: showDrawer,
	openDirection,
	childComponent: component,
	title,
	subtitle,
	testIds,
} = storeToRefs(useSideDrawerStore())

const { isFeedbackDialogOpen, feedbackDialogOptions } = useFeedbackTracker(
	FeedbackType.SINGLE_PRICE_CUSTOMIZATION_SHEET,
	3
)

const drawerPosition = computed(() => (openDirection.value === Directions.LEFT ? 'left' : 'right'))
const onClose = () => {
	useDateDetailsStore().reset()
}

const formattedSubtitle = computed(() => {
	if (typeof subtitle.value === 'string') {
		return subtitle.value as TranslationKey
	} else {
		return utilDate.formatExtendedDate(subtitle.value, useLocale().currentLocale.value) as TranslationKey
	}
})
</script>

<template>
	<ClientOnly>
		<FeedbackModal
			v-model="isFeedbackDialogOpen"
			:modal-options="feedbackDialogOptions"
			teleport-to="#dialogs-container"
			:position="{ top: '36px', right: '36px' }"
		/>

		<SpSideDrawer
			teleport-to="#dialogs-container"
			v-model="showDrawer"
			:position="drawerPosition"
			@close="onClose"
			:id="UserflowId.Drawer"
			:testIds="testIds"
			:blockNavigation="false"
		>
			<template #header-top>
				<div class="flex w-full flex-col gap-2 bg-white">
					<CommonText
						:text="title"
						:text-size="TextSizes.HEADING_MD"
						class="max-w-md break-words font-semibold leading-5 text-dark-blue-800"
						:translate="false"
					/>
					<CommonText :text="formattedSubtitle" :text-size="TextSizes.BODY_REGULAR" />
				</div>
			</template>

			<div class="h-full w-full overflow-auto">
				<component class="pb-20" v-if="component != undefined" :is="component" />
			</div>
		</SpSideDrawer>
	</ClientOnly>
</template>
